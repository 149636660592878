<template>
  <h5 class="title">2.Reflection Vanishing Point</h5>
  <p>
    The <strong style="color: #f27289">necessary condition</strong> to apply
    this technique is an
    <strong style="color: rgb(97, 189, 109)">image</strong> containing
    <strong style="color: rgb(250, 197, 28)">objects</strong> and their
    <strong style="color: rgb(250, 197, 28)">reflections</strong> through some
    <strong style="color: rgb(97, 189, 109)">reflective flat surface</strong>.
    The <strong style="color: rgb(97, 189, 109)">reflecting surface</strong> can
    be a flat mirror, lake surface or window glass. Then we can define
    <strong style="color: rgb(250, 197, 28)">Reflection Vanishing Point</strong>
    as follows:
  </p>
  <p>
    If we connect a <strong style="color: rgb(97, 189, 109)">point</strong> on
    the <strong style="color: rgb(250, 197, 28)">object</strong> with the
    <strong style="color: rgb(97, 189, 109)">corresponding point</strong> on its
    <strong style="color: rgb(250, 197, 28)">mirror image</strong>, we get a
    <strong style="color: #f27289">straight line</strong>. Do the same with
    <strong style="color: rgb(97, 189, 109)">other points</strong> on the
    <strong style="color: rgb(250, 197, 28)">object</strong>, we get a
    <strong style="color: #f27289">set of lines</strong>. If the image is not
    <strong style="color: rgb(97, 189, 109)">edited</strong>, these
    <strong style="color: #f27289">lines</strong> will intersect at a point
    called
    <strong style="color: rgb(250, 197, 28)">Reflection Vanishing Point</strong
    >. Note that this point may be out of range of the image
  </p>
  <p>
    For ease of visualization, observe the image on the right. Those
    <strong style="color: #f27289">red lines</strong> are lines connecting a
    <strong style="color: rgb(97, 189, 109)">point</strong> on a
    <strong style="color: rgb(250, 197, 28)">dinosaur skeleton</strong> with its
    <strong style="color: rgb(250, 197, 28)">reflection</strong> in a mirror.
    These <strong style="color: #f27289">lines</strong> intersect at
    <strong style="color: rgb(250, 197, 28)">Reflection Vanishing Point</strong>
    which is <strong style="color: rgb(97, 189, 109)">point v</strong>.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
