<template>
  <h4><b>Overview</b></h4>

  <p class="text">
    Metadata is included in most image files, which provides information about
    picture's pedigree, such as the type of camera used, color space
    information, application notes, etc. Information from metadata shows how the
    file was generated and handled, and it can be used to identify if it is from
    a digital camera, processed by a graphical program, or altered to convey
    misleading information.
  </p>

  <p class="text">
    Different picture formats include different types of metadata. For example:
  </p>

  <ul>
    <li>
      <b>BMP, PPM, PBM files</b>: These formats contain image dimensions, color
      space, and very little other information.
    </li>
    <li>
      <b>JPEG files (from cameras)</b>: This format usually contains a large
      variety of information, such as camera's make and model, timestamps, etc.
    </li>
    <li>
      <b>PNG files</b>: This format contain very little information, except that
      the image is converted from a JPEG or edited with Photoshop. Converted PNG
      files may include metadata from the source file format.
    </li>
  </ul>

  <h4><b>Consider Metadata</b></h4>
  <p class="text">
    Some common information in metadata that can be considered:
  </p>
  <ul>
    <li>
      <b>Make, Model, and Software</b>: These identify the device or application
      that created the picture. Most digital cameras include a Make and Model in
      the EXIF metadata block, except for original iPhone. Software may describe
      the camera's firmware version or application information.
    </li>
    <li>
      <b>Image size</b>: The metadata often records the picture's dimensions.
      Many applications resize or crop pictures without updating other metadata
      fields. So, we wonder if the rendered image size matches the other sizes
      in the metadata.
    </li>
    <li>
      <b>Timestamps</b>: These typically identify when a picture was taken or
      altered. Consider the timestamps match the expected timeframe.
    </li>
    <li>
      <b>Types of metadata</b>: There are many different metadata types. Some
      are only generated by cameras (Maker Notes metadata, PrintIM metadata),
      while others are only generated by applications (ICC Profile metadata,
      IPTC metadata, Photoshop metadata, XMP metadata).
    </li>
    <li>
      <b>Descriptions</b>: Many pictures include embedded annotations that
      describe the photo, identify the photographer, or itemize alteration
      steps.
    </li>
    <li>
      <b>Missing metadata</b>: A lack of specific metadata usually indicates a
      resaved picture and not an original photo, because some applications and
      online services strip out metadata. If the picture came from a digital
      camera, it should have camera-specific information.
    </li>
  </ul>
</template>

<script>
export default {
  emits: ["setTitle"],
  setup(props, context) {
    context.emit("setTitle", {
      title: "What is Metadata?",
    });
  },
};
</script>
