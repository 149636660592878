<template>
  <h5 class="title">6. Check Your Understanding</h5>
  <p>
    To make sure you understand the concept of
    <strong style="color: rgb(250, 197, 28)">Attached Shadow</strong>, we have a
    little exercise for you. Look at the
    <strong style="color: rgb(97, 189, 109)">image</strong> on the
    <strong style="color: rgb(97, 189, 109)">right</strong>, find
    <strong style="color: rgb(250, 197, 28)">5 Attached Shadows</strong> in this
    image to complete the exercise.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
