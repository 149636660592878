<template>
  <h5 class="title">3. Check Your Understanding</h5>
  <p>
    To make sure you understand
    <strong style="color: rgb(250, 197, 28)">Reflection Vanishing Point</strong
    >, we have a little exercise for you. The picture on the right is a
    <strong style="color: rgb(97, 189, 109)">headphone</strong> and its
    reflection in the <strong style="color: rgb(97, 189, 109)">mirror</strong>.
    There are 3 points of that color marked on the
    <strong style="color: rgb(250, 197, 28)">headphone</strong> and 3 points of
    blue marked on its reflection. Your task is to connect all
    <strong style="color: rgb(97, 189, 109)">red points</strong> with the
    corresponding <strong style="color: rgb(97, 189, 109)">blue points</strong>.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
