<template>
  <h5 class="title">2. Check Your Understanding</h5>
  <p>
    To test your understanding of the concept of
    <strong style="color: #f27289">Image Cloning</strong>, we will give you an
    exercise. We will show
    <strong style="color: rgb(250, 197, 28)">2 photos</strong> that have been
    edited by image cloning method, your task is to show the
    <strong style="color: rgb(97, 189, 109)">original part</strong> of image and
    the <strong style="color: rgb(97, 189, 109)">copied part</strong> of image
    in each given image.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
