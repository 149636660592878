<template>
  <h5 class="title">1. Introduction</h5>
  <p>
    In this tutorial we will help you understand a classic image spoofing
    technique called <strong style="color: #f27289">Image Splicing</strong>. We
    then show you how to detect an image tampered with using this technique
    using the
    <strong style="color: rgb(44, 130, 201)"
      >Color Filter Array Detection</strong
    >
    tool located on our
    <strong style="color: rgb(44, 130, 201)">FotoVerifier site</strong>.
  </p>
  <p>Through this tutorial, you will understand:</p>
  <ul>
    <li>
      <strong style="color: rgb(97, 189, 109)">RGB color system</strong> in an
      image.
    </li>
    <li>
      The concept of
      <strong style="color: rgb(97, 189, 109)">Color Filter Array</strong>.
    </li>
    <li>
      The idea of identify the
      <strong style="color: rgb(97, 189, 109)">Color Filter Array</strong> in an
      iamge to detect whether an image has been edited with
      <strong style="color: #f27289">Image Spicing</strong>.
    </li>
    <li>
      How to use the
      <strong style="color: rgb(44, 130, 201)"
        >Color Filter Array Detection</strong
      >
      tool on <strong style="color: rgb(44, 130, 201)">FotoVerifier</strong>.
    </li>
    <li>
      How to
      <strong style="color: rgb(250, 197, 28)">observe the results</strong> of
      the tool.
    </li>
  </ul>
  <p>Let's get started now!</p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
