<template>
  <h5 class="title">7. Check Your Understanding</h5>
  <p>
    To make sure you understand the concept of
    <strong style="color: rgb(97, 189, 109)">Color Filter Array</strong>, you
    need to complete a little exercise. This is a
    <strong style="color: rgb(250, 197, 28)">multiple choice test</strong>
    consisting of <strong style="color: rgb(250, 197, 28)">8 questions</strong>,
    you need to choose the correct answer for all
    <strong style="color: rgb(250, 197, 28)">8 questions</strong> before moving
    on to the next step.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}

.window {
  background-color: #2c3967;
  border: 1px solid rgba(128, 152, 255, 0.22);
  box-shadow: 0 0.1875rem 1px -2px rgba(0, 0, 0, 0.2),
    0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.14),
    0 1px 0.3125rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
</style>
