<template>
  <h5 class="title">4. Check Your Understanding</h5>
  <p>
    To make sure you understand the two purposes of
    <strong style="color: #f27289">Image Cloning</strong>, we offer a small
    exercise. In this exercise you will be provided with
    <strong style="color: rgb(44, 130, 201)">2 pairs of images</strong>, in each
    pair of images will include the
    <strong style="color: rgb(97, 189, 109)">original image</strong> and the
    <strong style="color: rgb(97, 189, 109)">faked image</strong> after editing
    with <strong style="color: #f27289">Image Cloning</strong>. Your task is to
    determine <strong style="color: rgb(250, 197, 28)">the purpose</strong> of
    image cloning for each given pair of images.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
