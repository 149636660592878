<template>
  <h5 class="title">1. Introduction</h5>
  <p>
    In this tutorial we will help you understand a classic image spoofing
    technique called <strong style="color: #f27289">Image Splicing</strong>. We
    then show you how to detect a
    <strong style="color: rgb(97, 189, 109)">JPEG image</strong> tampered with
    using this technique using the
    <strong style="color: rgb(44, 130, 201)">JPEG Ghost</strong> tool located on
    our <strong style="color: rgb(44, 130, 201)">FotoVerifier site</strong>.
    Thereby you will understand the method of
    <strong style="color: rgb(97, 189, 109)">compressing JPEG images</strong>,
    how the <strong style="color: rgb(44, 130, 201)">JPEG Ghost</strong> tool
    works, how to use and
    <strong style="color: rgb(250, 197, 28)">observe the results</strong> of
    this tool.
  </p>
  <p>Let's get started now!</p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
