<template>
  <h5 class="title">2. Image Splicing</h5>
  <p>
    <strong style="color: #f27289">Image Splicing</strong> is a classic image
    spoofing technique. To perform this technique, one will
    <strong style="color: rgb(250, 197, 28)">copy an area</strong> of the
    <strong style="color: rgb(97, 189, 109)">first image</strong> and then
    <strong style="color: rgb(250, 197, 28)">paste the copied area</strong> into
    the <strong style="color: rgb(97, 189, 109)">second image</strong>. The
    <strong style="color: rgb(250, 197, 28)">purpose</strong> of this technique
    is to alter the content of the
    <strong style="color: rgb(97, 189, 109)">second image</strong>.
  </p>
  <p>
    For example, when you want a photo of yourself standing in a certain
    landscape but you can't get there to take it, you can
    <strong style="color: rgb(250, 197, 28)">copy your standing photo</strong>
    from <strong style="color: rgb(97, 189, 109)">another photo</strong> and
    then <strong style="color: rgb(250, 197, 28)">paste it</strong> on a
    <strong style="color: rgb(97, 189, 109)">landscape photo</strong> you want.
    So you have a picture like you want. Very easy, isn't it?
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
