<template>
  <h5 class="title">3. Cast Shadow</h5>
  <p>
    In this step we will learn the first type of
    <strong style="color: rgb(250, 197, 28)">shadow</strong>, called
    <strong style="color: rgb(250, 197, 28)">Cast Shadow</strong>.
  </p>
  <p>
    When there is an
    <strong style="color: rgb(97, 189, 109)">object</strong> blocking the
    <strong style="color: rgb(97, 189, 109)">light source</strong>, the shape of
    that <strong style="color: rgb(97, 189, 109)">object</strong> will be
    <strong style="color: rgb(250, 197, 28)">projected</strong> onto the
    <strong style="color: rgb(97, 189, 109)">plane</strong>
    opposite the <strong style="color: rgb(97, 189, 109)">light source</strong>.
    This area is a
    <strong style="color: rgb(250, 197, 28)">dark area</strong> because it does
    not receive light from the
    <strong style="color: rgb(97, 189, 109)">light source</strong> which is
    covered by the <strong style="color: rgb(97, 189, 109)">object</strong>.
    This <strong style="color: rgb(250, 197, 28)">dark area</strong> is called
    <strong style="color: rgb(250, 197, 28)">Cast Shadow</strong>.
  </p>
  <p>
    To make it easier to imagine, look at the photo on the right. The
    <strong style="color: rgb(97, 189, 109)">light source</strong> in the image
    is <strong style="color: rgb(97, 189, 109)">sunlight</strong> shining from
    left to right, the
    <strong style="color: rgb(97, 189, 109)">object</strong> is a
    <strong style="color: rgb(97, 189, 109)">boy</strong> and his
    <strong style="color: rgb(250, 197, 28)">shadow</strong> is reflected on the
    <strong style="color: rgb(97, 189, 109)">ground</strong> is a
    <strong style="color: rgb(250, 197, 28)">Cast Shadow</strong>.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
