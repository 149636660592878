<template>
  <h5 class="title">5. Attached Shadow</h5>
  <p>
    In this step we will learn the second type of
    <strong style="color: rgb(250, 197, 28)">shadow</strong>, called
    <strong style="color: rgb(250, 197, 28)">Attached Shadow</strong>.
  </p>
  <p>
    When the <strong style="color: rgb(97, 189, 109)">light</strong> from the
    <strong style="color: rgb(97, 189, 109)">light source</strong> only
    illuminates a part of the
    <strong style="color: rgb(97, 189, 109)">object</strong>, the
    <strong style="color: rgb(250, 197, 28)">unlit part</strong>
    will be a <strong style="color: rgb(250, 197, 28)">dark area</strong>,
    called the
    <strong style="color: rgb(250, 197, 28)">Attached Shadow</strong>. In fact,
    <strong style="color: rgb(250, 197, 28)">Attached Shadows</strong> usually
    appear on convex surfaces of objects, such as the folds of a shirt.
  </p>
  <p>
    To make it easier to imagine, look at the photo on the right. The
    <strong style="color: rgb(97, 189, 109)">two objects</strong> in the image
    are a <strong style="color: rgb(97, 189, 109)">cylinder</strong> and a
    <strong style="color: rgb(97, 189, 109)">sphere</strong> with only the
    <strong style="color: rgb(250, 197, 28)">left part</strong> illuminated, so
    the <strong style="color: rgb(250, 197, 28)">right part</strong> is
    <strong style="color: rgb(250, 197, 28)">Attached Shadow</strong>. The red
    line is the dividing line between the
    <strong style="color: rgb(250, 197, 28)">illuminated area</strong> and the
    <strong style="color: rgb(250, 197, 28)">Attached Shadow area</strong>.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
