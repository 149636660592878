<template>
  <div class="container" style="">
    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-warning" role="alert">
          Function has not yet implemented!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadedImage: String,
  },
  setup() {},
};
</script>
