<template>
  <h5 class="title">5. Check Your Understanding</h5>
  <p>
    To make sure you understand the concept of
    <strong style="color: rgb(97, 189, 109)">JPEG Image Compression</strong>,
    you'll need to do an exercise. Given an
    <strong style="color: rgb(44, 130, 201)">original image</strong> from the
    camera, that is,
    <strong style="color: rgb(44, 130, 201)">an uncompressed image</strong>, you
    need to adjust the
    <strong style="color: rgb(250, 197, 28)">Quality</strong> parameter value so
    that the
    <strong style="color: rgb(44, 130, 201)">compressed image</strong> has a
    size <strong style="color: rgb(250, 197, 28)">37 KB</strong>.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
