<template>
  <div class="alert alert-warning" role="alert">
    Warning! We do not guarantee your privacy when you use this tool, as your
    image is processed at these search engine's server.
  </div>
  <div class="alert alert-primary" role="alert">
    Please choose an engine and upload your image
  </div>
  <div>
    <a id="ris-google" href="https://images.google.com/" target="_blank"
      >Google Image Search</a
    >
  </div>
  <div>
    <a id="ris-bing" href="https://www.bing.com/visualsearch" target="_blank"
      >Bing Visual Search</a
    >
  </div>
  <div>
    <a id="ris-tineye" href="https://tineye.com/" target="_blank">TinEye</a>
  </div>
</template>
