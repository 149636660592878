<template>
  <center style="padding-top: 100px">
    <p style="font-size: 40px">Welcome to</p>
    <p class="title">FotoVerifier Browser</p>
  </center>
</template>

<style scoped>
.title {
  margin-top: 0;
  color: #2a59fe;
  font-size: 40px;
  font-weight: 900;
}
</style>
