<template>
  <h5 class="title">3. Purposes of Image Cloning</h5>
  <p>
    <strong style="color: #f27289">Image Cloning</strong> has
    <strong style="color: rgb(250, 197, 28)">2 purposes</strong>:
  </p>
  <ul>
    <li>
      <strong style="color: rgb(250, 197, 28)">Purpose 1:</strong> To hide
      something in the original image by overwriting another area of the image
      on the content to be hidden
    </li>
    <li>
      <strong style="color: rgb(250, 197, 28)">Purpose 2:</strong> To rely on
      the details available in the image to create new details, not in the
      original image to change the image content
    </li>
  </ul>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
