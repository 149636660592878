<template>
  <h5 class="title">1. Introduction</h5>
  <p>
    In this tutorial, we'll help you learn how to use
    <strong style="color: #f27289">Shadow Consistency Checking</strong> to
    detect if an <strong style="color: rgb(97, 189, 109)">image</strong> has
    been <strong style="color: rgb(97, 189, 109)">edited</strong>. After
    completing this tutorial, you will understand:
  </p>
  <ul>
    <li>
      <strong style="color: rgb(250, 197, 28)">Two types</strong> of
      <strong style="color: rgb(250, 197, 28)">shadows</strong> in the image:
      <strong style="color: rgb(250, 197, 28)">Cast Shadow</strong> and
      <strong style="color: rgb(250, 197, 28)">Attached Shadow</strong>.
    </li>
    <li>
      Ideas on using
      <strong style="color: #f27289">Shadow Consistency Checking</strong> to
      <strong style="color: rgb(97, 189, 109)">detect editing</strong> in
      <strong style="color: rgb(97, 189, 109)">images</strong>.
    </li>
    <li>
      How to use the
      <strong style="color: #f27289">Check Shadow Consistency</strong> tool on
      the <strong style="color: rgb(44, 130, 201)">FotoVerifier</strong> site to
      check if an <strong style="color: rgb(97, 189, 109)">image</strong> has
      been <strong style="color: rgb(97, 189, 109)">edited</strong> or not.
    </li>
    <li>
      How to observe the
      <strong style="color: rgb(97, 189, 109)">results</strong> to find the
      <strong style="color: rgb(97, 189, 109)">edited area</strong>.
    </li>
  </ul>
  <p>Let's get started now!</p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
