<template>
  <h5 class="title">3. Check Your Understanding</h5>
  <p>
    To make sure you understand the concept of
    <strong style="color: #f27289">Image Splicing</strong>, we have a little
    exercise for you. In this exercise you will be given
    <strong style="color: rgb(97, 189, 109)">2 pictures</strong>, the first
    photo called
    <strong style="color: rgb(97, 189, 109)">Image 1</strong> contains an image
    of a standing <strong style="color: rgb(250, 197, 28)">man</strong>, the
    second image called
    <strong style="color: rgb(97, 189, 109)">Image 2</strong> contains an image
    of a <strong style="color: rgb(250, 197, 28)">beach</strong>. Your task is
    to <strong style="color: rgb(44, 130, 201)">copy</strong> the image of the
    <strong style="color: rgb(250, 197, 28)">man</strong> standing in Image 1
    and <strong style="color: rgb(44, 130, 201)">paste</strong> it at a given
    location on the <strong style="color: rgb(250, 197, 28)">beach</strong> in
    Image 2 to form the image of
    <strong style="color: rgb(250, 197, 28)"
      >the man standing on the beach</strong
    >.
  </p>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.title {
  color: #c0cbeb;
}

ul {
  padding-left: 20px;
}
</style>
